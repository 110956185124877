body {
  margin: 0;
  font-family: Arial, sans-serif;
}

.navbar {
  background-color: #fff;
  border-bottom: 1px solid #ccc;
  padding: 10px 0;
}

.navbar ul {
  list-style-type: none;
  display: flex;
  justify-content: space-around;
}

.navbar ul li {
  display: inline;
}

.navbar ul li button {
  background: none;
  border: none;
  text-decoration: none;
  color: #333;
  cursor: pointer;
}

.navbar ul li button:hover {
  color: #00C8C8;
}