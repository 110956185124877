@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap');

$gray-900: #8790A4 !default;
$blue: #008080 !default;

$primary: #008080 !default;
$secondary: #DDEAEA !default;
$tertiary: #8790A4 !default;
$light: #F7F7F7 !default;

// Override default Bootstrap colors
$success: #28a745; // Custom success color (green)
$info: #0088CE; // Custom info color (blue)
$warning: #FFB020; // Custom warning color (yellow)
$danger: #DC2626; // Custom danger color (red)
$red: #BB251A;
$gray: #565E70; // Custom light color (light gray)
$dark: #2B3034; // Custom dark color (dark gray)
$font-family-sans-serif: "Inter", system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !default;

$error-bg: #FDF4F4;
$error-message : #A73636;
$error-message-text : #696F8C;
$error-border-color : #D14343;


$success-bg: #F5FBF8;
$success-message : #317159;
$success-message-text : #696F8C;
$success-border-color : #52BD94;

// Text style variables
$heading-font-size: 24px !default; // Size for headings
$subheading-font-size: 20px !default; // Size for subheadings
$paragraph-font-size: 14px !default; // Size for paragraphs
$anchor-font-size: 14px !default; // Size for anchor tags
$list-font-size: 14px !default; // Size for anchor tags


$heading-font-weight: 500 !default; // Bold font weight for headings
$subheading-font-weight: 500 !default; // Semi-bold font weight for subheadings
$paragraph-font-weight: 500 !default; // Normal font weight for paragraphs
$anchor-font-weight: 500 !default; // Normal font weight for anchor tags
$list-font-weight: 500 !default; // Normal font weight for paragraphs

$heading-line-height: 28px;
$subheading-line-height: 28px;
$paragraph-line-height: 16px;
$anchor-line-height: 16px;
$list-line-height: 16px;

$heading-color: $dark !default; // Heading color
$subheading-color: $dark !default; // Subheading color (using $blue)
$paragraph-color: $gray !default; // Paragraph text color (using $dark)
$anchor-color: $info !default; // Anchor text color (using $primary)
$anchor-hover-color: darken($primary, 10%) !default; // Anchor hover color (darkened primary)
$list-color: $gray !default; // Paragraph text color (using $dark)


// SCSS Variable Definitions
$btn-font-size: 14px; // Button font size
$btn-font-weight: 600; // Button font weight (semi-bold)
$btn-line-height: 16px; // Button line height



// SCSS Variables
$body-color: $dark; // Dark gray text color
$border-color: #D8DAE5; // Light gray border color
$bs-border-color: #8790A4;
$bg-tertiary: #E5E7EB;

@import "~bootstrap/scss/bootstrap";

:root {
    --bs-tertiary-color: #{$tertiary};
}

*,
body {
    font-family: $font-family-sans-serif !important;
    color: $dark;
    font-size: $paragraph-font-size;
    line-height: $paragraph-line-height;
}

// Apply styles for headings, subheadings, paragraphs, and anchor tags
h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: $font-family-sans-serif;
    font-size: $heading-font-size;
    font-weight: $heading-font-weight;
    color: $heading-color;
    margin-bottom: 0;
}

h2,
h3,
h4,
h5,
h6 {
    font-size: $subheading-font-size;
    font-weight: $subheading-font-weight;
    color: $subheading-color;
}

p {
    font-family: $font-family-sans-serif;
    font-size: $paragraph-font-size;
    font-weight: $paragraph-font-weight;
    color: $paragraph-color;
    line-height: $paragraph-line-height;
    margin: 0;
    padding: 0;
}

ul,
li {
    font-family: $font-family-sans-serif;
    font-size: $list-font-size;
    font-weight: $list-font-weight;
    color: $list-color;
}

a {
    font-family: $font-family-sans-serif;
    font-size: $anchor-font-size;
    font-weight: $anchor-font-weight;
    color: $anchor-color;
    text-decoration: none;
    transition: color 0.2s ease-in-out;
    cursor: pointer;

    &:hover,
    &:focus {
        color: $anchor-hover-color;
        text-decoration: none;
    }
}

// Button Styles
.btn {
    font-size: $btn-font-size; // Set font size from variable
    font-weight: $btn-font-weight; // Set font weight from variable
    line-height: $btn-line-height; // Set line height from variable
    padding: 8px 16px; // Button padding
}


// You can even use the variable for border color
input {
    border: 1px solid $border-color; // Use border-color variable for input borders
}

input {
    border: 1px solid $border-color; // Use border-color variable for input borders
}

input[type="checkbox"] {

    border: 1px solid $bs-border-color; // Use border-color variable for input borders

}

.bg-tertiary {
    background-color: $bg-tertiary !important;
}