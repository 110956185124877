@import "../../../styles.scss";


$table-border: 1px solid #dee2e6;
$primary-color: #008080;
$hover-bg: rgba(0, 128, 128, 0.1);
$subtext-color: #6c757d;

.task-automation {


    .avg-augmentation {
        margin-top: 14px;
    }

    .skill-reason {
        // padding-inline: 30px; // Added inline padding
        display: inline-block; // Keeps it in proper shape
        margin-top: 5px;
    }

    // Table Styles
    .table {

        th,
        td {
            padding: 12px 30px;
            text-align: left;
            vertical-align: middle;
            width: 45%;
        }

        th {
            padding-inline: 30px !important;
        }

        tbody tr {

            &:hover {
                background-color: $hover-bg;
            }
        }
    }

    .table-header {
        font-weight: 500;
        font-size: 14px;
    }

    // Progress Bar Styling
    .progress-bar-container {
        width: 451px;
        height: 32px;
        background-color: $bg-tertiary;
        border-radius: 300px;
        position: relative;
        overflow: hidden;
    }

    .progress-bar-custom {
        height: 100%;
        background-color: $info;
        //   border-radius: 300px;
        text-align: center;
        color: $white;
        font-weight: 600;
        line-height: 32px;
        font-size: 16px;
    }

    // Spacing for expanded row
    .expanded-row td {
        padding-top: 20px !important;
    }

    // Skill item styling
    .skill-item {
        display: flex;
        align-items: center;
        gap: 10px;
    }

    .bullet-icon {
        width: 21px;
        height: 21px;
    }

    .skill-text {
        font-weight: 500;
        font-size: 14px;
    }

    .subtext {
        font-size: 12px;
        color: $subtext-color;
        margin-top: 3px;
    }

    // Responsive styles
    @media (max-width: 768px) {
        .table-header {
            font-size: 12px;
            text-align: center;
        }

        .subtext {
            font-size: 10px;
        }

        td,
        th {
            font-size: 12px;
            padding: 0.5rem;
            text-align: center;
        }
    }

    // Accordion Table Styling
    .accordion-table {
        // width: 1872px !important;
        border-collapse: collapse;
    }

    // Table Header 
    .accordion-header th {
        background-color: #E6F2F2 !important;
        height: 48px;
        text-align: left;
        color: $dark !important;
        text-transform: uppercase;
    }

    // Table Data Row
    .accordion-row {
        height: 71px;
        border-bottom-width: 1px;
        text-align: left;
    }

}