@import "../../styles.scss";

/* drawer.css */
.drawer {
  position: fixed;
  top: 0;
  right: -50%;
  width: 50vw;
  height: 100vh;
  background-color: #ffffff;
  transition: right 0.3s ease-in-out;
  box-shadow: -2px 0px 10px rgba(0, 0, 0, 0.1);
  z-index: 1050;
}

.drawer.open {
  right: 0;
}

.drawer-header {
  background-color: $bg-tertiary;
  padding: 23px 28px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 80px;
}

.drawer-header h4 {
  margin-left: 2px;
}

.drawer-body {
  padding: 17px 28px;
  overflow-y: scroll;
  height: 90vh;
  text-align: left;
}

.drawer-body h5 {
  font-weight: 700;
  font-size: 14px;
  line-height: 26px;
  letter-spacing: -0.2px;
}

button.close {
  background: none;
  border: none;
  color: white;
  font-size: 12px;
  cursor: pointer;
}

/* Fullscreen overlay */
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1040;
  display: block;
  cursor: pointer;
}


/* Custom file input styling */
.custom-file-upload {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px dashed $primary;
  height: 135px;
  text-align: center;
  cursor: pointer;
  position: relative;
}

.custom-file-upload input[type="file"] {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
  z-index: 2;
}

.file-upload-area {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.file-upload-area svg {
  margin-bottom: 8px;
}

.file-upload-area p {
  margin: 0;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  padding-bottom: 8px;
}

.file-upload-area p span {
  font-weight: 600;
  color: $primary;
}

.file-info {
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  text-align: center;
  color: #696F8C;
}

.upload-section {
  // margin-bottom: 43px;
}

.check-mark {
  display: inline-block;
  font-size: 18px;
  animation: checkMarkAnimation 2s ease-in-out infinite !important;
  opacity: 0;
  margin-left: 10px;
}

/* Keyframe for animation */
@keyframes checkMarkAnimation {
  0% {
    opacity: 0;
    transform: scale(0);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.info_heading {
  padding: 10px;
  margin-bottom: 20px;
  font-weight: 500;
  font-size: 14px;
  line-height: 16.94px;
  color: $dark !important;
}

/* Custom Tabs */
.drawer-header {
  .custom-tabs {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  .custom-tabs .tab-title {
    font-weight: 600;
    font-size: 18px;
    line-height: 28px;
    letter-spacing: 0.4px;
    color: $dark !important;
    padding: 10px 15px;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    border-bottom: 2px solid transparent;

  }

  .custom-tabs .tab-title.active {
    color: $primary !important;
    border-bottom: 2px solid $primary;
  }

  .custom-tabs .tab-title:hover {
    color: $primary !important;
  }

}


.google-safety-link {
  color: $info;
  text-decoration: underline;
  font-weight: 400;
  z-index: 100;
  cursor: pointer;
}

.text-danger {
  z-index: 10;
}

.google-safety-link:hover,
.google-safety-link:focus {
  color: $info;
  text-decoration: underline;
}

.error-message-box {
  display: flex;
  background: $error-bg;
  padding: 12px 16px;
  border: 1px solid $error-border-color;
  border-radius: 8px;
}

.error-message-holder {
  margin-left: 15px;
}

.error-message {
  color: $error-message;
  margin-bottom: 4px;
}

.error-message-text {
  color: $error-message-text;
  line-height: 18px;
  word-wrap: break-word;
  word-break: break-all;
}


.success-message-box {
  display: flex;
  background: $success-bg;
  padding: 12px 16px;
  border: 1px solid $success-border-color;
  border-radius: 8px;
}

.success-message-holder {
  margin-left: 15px;
  max-width: 280px;
}

.success-message {
  color: $success-message;
  margin-bottom: 4px;
}

.success-message-text {
  color: $success-message-text;
  word-wrap: break-word;
  word-break: break-all;
}