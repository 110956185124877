@import "../../styles.scss";

.bg-body-2 {
    background: $secondary !important;
}

.navbar-brand {
    background: $white;
    width: 100px;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    left: -10.9px;
}

.m-80 {
    margin-top: 80px;
}

.w-80 {
    width: 100px;
    height: calc(100vh - 80px);
}

.w-500 {
    width: 500px;
}

#left-nav {

    a {
        color: $white;
        text-align: center;
        padding-left: 0;
        padding-right: 0;
        font-size: 12px;

        .svg {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 40px;
            height: 40px;
            background: $primary;
            box-shadow: 0px 2px 2px 0px #0000001A;
            ;
            border-radius: 6px;
            margin: auto;
            margin-bottom: 7px;

            path {
                fill: $white;
            }
        }

        &.active {
            background: url("data:image/svg+xml,%3Csvg width='16' height='48' viewBox='0 0 16 48' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M16 24.5C16 36.9264 16 27 16 48C16 36 0 36.9264 0 24.5C0 12.0736 15.5 15.5 16 0C16 14 16 12.0736 16 24.5Z' fill='%23F7F7F7'/%3E%3Ccircle cx='10' cy='24' r='4' fill='%23008080'/%3E%3C/svg%3E%0A") no-repeat right;

            .svg {
                background: $white;

                path {
                    fill: $primary;
                }

            }
        }
    }

}

.fixed-height-container {
    max-height: calc(100vh - 80px);
    overflow-y: scroll;
    scrollbar-width: none;
}

/* For Webkit browsers (Chrome, Safari) */
.fixed-height-container::-webkit-scrollbar {
    display: none;
}

.notification-count {
    position: absolute;
    top: -15px;
    left: 6px;
    background-color: $red;
    color: white;
    border-radius: 50%;
    padding: 3px 8px;
    font-size: 11px;
    font-weight: bold;
    display: inline-block;
    min-width: 20px;
    text-align: center;
    z-index: 1;
}

.notification-dropdown.dropdown-menu.show.dropdown-menu-end {
    width: 300px;
    height: 200px;
    top: 15px;
    right: 10px;
    overflow-y: scroll;
    scrollbar-width: none;
    padding: 15px;

    .read.dropdown-item {
        font-size: 14px;
        color: $dark;
        padding: 5px;
        border-bottom: 1px solid $bg-tertiary;
    }

}

/* For Webkit browsers (Chrome, Safari) */

.notification-dropdown.dropdown-menu.show.dropdown-menu-end::-webkit-scrollbar {
    display: none;
    /* Hides the scrollbar */
}

.show.dropdown {
    z-index: 10001;
}

.sb-icon-container {
    position: fixed;
    bottom: 15px;
    left: 0;
    width: 100px;
    z-index: 1000;
}

.sb-icon {
    width: 100%;
    height: auto;
}

.profile-initials {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: $primary;
    color: $white;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    font-weight: bold;
}

.bell-icon {
    margin: 0 24px;
}