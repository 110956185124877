/* Container styles */
.diff-checker-container {
    width: 100%;
    height: 500px;
    display: flex;
    text-align: left;
    flex-direction: column;
    max-width: 85vw !important;
    padding: 0 !important;
}

/* .diff-checker-header {
    text-align: center;
    margin: 10px 0;
    font-size: 1.5em;
    font-weight: bold;
} */

/* Panels */
.diff-checker-panel {
    width: 50%;
    padding: 11px 24px;
    overflow-y: scroll;
    height: 100%;
    border-right: 1px solid #F3F4F6;
    background: #fff;
    box-sizing: border-box;
}

/* Firefox */
.diff-checker-panel,
.rc-md-editor .editor-container .sec-md .input,
.rc-md-editor .editor-container .sec-html .html-wrap {
    scrollbar-width: thin;
    /* Thin scrollbar */
    scrollbar-color: #DDEAEA #fff;
    /* Thumb color and track color */
}

/* Webkit-based browsers (Chrome, Safari, Edge) */

/* Style the scrollbar itself */
.diff-checker-panel::-webkit-scrollbar,
.rc-md-editor .editor-container .sec-md .input::-webkit-scrollbar,
.rc-md-editor .editor-container .sec-html .html-wrap::-webkit-scrollbar {
    width: 7px;
    /* For vertical scrollbar */
    height: 167px;
    /* For horizontal scrollbar */
}

/* Style the scrollbar track (background) */
.diff-checker-panel::-webkit-scrollbar-track,
.rc-md-editor .editor-container .sec-md .input::-webkit-scrollbar-track,
.rc-md-editor .editor-container .sec-html .html-wrap::-webkit-scrollbar-track {
    background: #fff;
    /* Light gray background */
    border-radius: 10px;
    /* Optional rounded corners */
}

/* Style the scrollbar thumb (the draggable part) */
.diff-checker-panel::-webkit-scrollbar-thumb,
.rc-md-editor .editor-container .sec-md .input::-webkit-scrollbar-thumb,
.rc-md-editor .editor-container .sec-html .html-wrap::-webkit-scrollbar-thumb {
    background: #DDEAEA;
    /* Darker color for the thumb */
    border-radius: 10px;
    /* Optional rounded corners */
}

/* On hover, change the thumb color */
.diff-checker-panel::-webkit-scrollbar-thumb:hover,
.rc-md-editor .editor-container .sec-md .input::-webkit-scrollbar-thumb:hover,
.rc-md-editor .editor-container .sec-html .html-wrap::-webkit-scrollbar-thumb:hover {
    background: #555;
    /* Darker thumb when hovered */
}


/* Header for panels */
/* .diff-checker-panel h3 {
    margin-bottom: 10px;
    font-size: 1.2em;
} */

/* Diff styling */
.diff-checker-diff {
    overflow: hidden;
    padding: 16px;

    h1,
    h2 {
        font-family: "Inter";
        font-weight: 700;
        font-size: 24px;
        line-height: 155%;
        letter-spacing: 0.15px;
    }

    h3,
    h4,
    h5,
    h6,
    th {
        font-family: "Inter";
        font-weight: 700;
        font-size: 18px;
        line-height: 155%;
        letter-spacing: 0.15px;
    }

    p,
    ul,
    td,
    li {
        font-family: "Inter";
        font-weight: 500;
        font-size: 16px;
        line-height: 155%;
        letter-spacing: 0.15px;
    }
}

/* Markdown editor adjustments */
/* .markdown-editor {
    height: calc(100% - 50px);
    max-height: calc(100vh - 240px);
    overflow-y: scroll;
    scrollbar-width: none;
    max-width: 85vw;
} */

/* For Webkit browsers (Chrome, Safari) */
/* Hides the scrollbar */

/* .markdown-editor::-webkit-scrollbar {
    display: none;
} */

/* Buttons */
/* .diff-checker-button {
    margin-top: 10px;
    padding: 8px 15px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1em;
} */
/* 
.diff-checker-button-save {
    background-color: #4caf50;
    color: white;
} */

/* .diff-checker-button-edit {
    background-color: #2196f3;
    color: white;
} */

/* Diff highlights */
/* .diff-added {
    background-color: #10B84D1A;
}

.diff-removed {
    background-color: #FA3C39;
    text-decoration: line-through;
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

.SplitPane {
    height: 100%;
}

.Pane {
    overflow-y: auto;
}

table {
    border-collapse: collapse;
    width: 100%;
}

th,
td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
}

th {
    background-color: #f2f2f2;
}

.diff-added {
    background-color: lightgreen;
}

.diff-removed {
    background-color: lightcoral;
    text-decoration: line-through;
} */

/* 
.diff-checker-panel {
    position: relative;
    overflow-y: auto;
    height: 100%;
    flex-grow: 1;
    padding-right: 20px; 
    padding-left: 20px;
  }
  
  .diff-checker-container {
    display: flex;
    height: 100%;
    width: 100%;
  }
   */