@import "../../styles.scss";


.auth-heading {
    font-weight: 600;
    font-size: 20px;
    line-height: 100%;
    margin-bottom: 10px;
}

.auth-subheading {
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0%;
}

.auth-page-img {
    width: 100%;
    max-width: 464px;
    height: auto;
}

.auth-page-error-box,
.auth-page-success-box {
    margin: 10px auto;

    .error-message,
    .success-message {
        margin-bottom: 0;
        text-align: left;
    }

    .error-message-text,
    .success-message-text {
        margin-top: 3px;
    }

    .success-message-holder {
        max-width: unset;
    }
}

.info-message-box {
    display: flex;
    background: #EEFFFC;
    padding: 12px 16px;
    border: 1px solid #008080;
    border-radius: 8px;

    .info-message-holder {
        margin-left: 15px;
        text-align: left;
    }

    .info-message-text {
        color: #696F8C;
        word-wrap: break-word;
        word-break: break-all;
    }
}